/*
.table {
    td {
        background-color: transparent;      // new bootstrap forces td background-color --bs-table-bg preventing our tr background color to sip through
    }
}
 */

.table {
    --bs-table-bg: var(--bs-body-bg);       // redefine old bootstrap behavior of table bg equal to body bg
}

tr.qty-none-covered {
    background-color: rgb(226, 89, 89) !important;
}

tr.qty-some-covered {
    background-color: rgb(255, 214, 97) !important;
}

tr.qty-all-covered {
    background-color: rgb(118, 255, 124) !important;
}
