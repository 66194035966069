// Body
// $body-bg: #d9d9d9b3;
$primary: #2f405b;
$secondary: #f3ebc2;
// Typography
// $font-family-sans-serif: 'Alegreya Sans', sans-serif;
// $font-size-base: 0.8rem;
$line-height-base: 1.6;

$bs-navbar-active-color: #fff;
$active-color: #fff;
$navbar-active-color: #fff;
// $nav-link-font-size: 0.7rem;