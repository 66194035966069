.theme2 .bg-primary {
    background-color: rgb(118, 91, 170) !important;
}

.theme2 {
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background-color:  rgb(118, 91, 170) !important;
    }

    .nav-link.active, .nav-link:hover {
        background-color:  rgb(118, 91, 170) !important;
    }
}
