input.original-value-changed {
    background-color: lightgoldenrodyellow;
    font-weight: bold;
}

input.original-value-locked {
    background-color: #f2f2ff;
}


input.original-value-locked + label {
//    background-color: blueviolet;
}



