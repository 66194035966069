// Fonts
@import url('https://fonts.bunny.net/css?family=alegreya-sans:100,100i,300,300i,400,400i,500,500i,700,700i,800,800i,900,900i');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

@import 'bootstrap-icons/font/bootstrap-icons';

// Bootstrap
@import 'custom';

@import "qty-covered-styling";
@import "original-value-styling";
@import "theme2";
